html {
  font-weight: 300;
}

body {
  background-color: #cbd5e0;
  font-family: sans-serif, "Segoe UI", Tahoma, Geneva, Verdana;
}

.main-container {
  height: 100vh;
  width: 100%;
  max-width: 100vw;
}

.navContainer {
  margin-left: auto;
  margin-right: 0;
}

.navContainer svg {
  color: #fff;
}

a {
  text-decoration: none;
}
